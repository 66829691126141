// src/components/header.js
import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitter,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons"
import { StaticImage } from "gatsby-plugin-image"

export default function Footer() {
  return (
    <footer className="text-gray-700 px-6 pt-6 pb-2 bottom-0 max-w-4xl mx-auto">
      <div className="flex items-center text-3xl pb-4">
        <div className="w-9 mr-2">
          <Link to="/">
            <StaticImage src="../images/icon.png" alt="GoodTe" />
          </Link>
        </div>
        <div>GoodTe</div>
      </div>

      <div className="pb-6">
        グッテ(GoodTe)は、患者と患者に関わる全ての人が繋がり、社会への働きかけを通して、患者が安心して暮らせる社会を実現します。
      </div>
      <div className="text-xl text-center">
        <div className="flex justify-center flex-wrap pb-3">
          <div className="w-1/2 pb-1">
            <Link to="/#top">Top</Link>
          </div>
          <div className="w-1/2 pb-1">
            <Link to="/news">News</Link>
          </div>
          <div className="w-1/2 pb-1">
            <Link to="/about">About Us</Link>
          </div>
          <div className="w-1/2 pb-1">
            <Link to="/service">Service</Link>
          </div>
          <div className="w-1/2 pb-1">
            <Link to="/contact">Contact</Link>
          </div>
          <div className="w-1/2 pb-1">
            <Link to="/privacy">Privacy</Link>
          </div>
        </div>
      </div>

      <div className="text-center text-2xl">
        <a
          href="https://twitter.com/GCareCommunity"
          className="px-2"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        <a
          href="https://www.instagram.com/gcare_community/"
          className="px-2"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://www.facebook.com/G%E3%82%B3%E3%83%9F%E3%83%A5%E3%83%8B%E3%83%86%E3%82%A3%E7%8[…]3%81%8D%E3%81%AB%E7%94%9F%E3%81%8D%E3%82%8B-450406872450715/"
          className="px-2"
          target="_blank"
          rel="noreferrer noopener"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
      </div>

      <div className="text-center text-sm pt-2">
        © {new Date().getFullYear()} GoodTe inc. All Rights Reserved.
      </div>
    </footer>
  )
}
